<template>
  <v-row align="center" justify="center" class="pa-4">
    <v-col>
      <h1 style="font-weight: 800; color: #FF0000">เพิ่มบัตรเงินสด</h1>
    </v-col>
    <v-card class="pa-8" width="100%">
      <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-8"
            ><span class="pt-/">สถานะ</span>
            <span style="color: red">*</span>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <v-switch v-model="activeFlag" label="ใช้งาน"></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">ชื่อบัตรเงินสด</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              v-model="voucherName"
              label="ชื่อบัตรเงินสด"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">ประเภทบัตรเงินสด</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-select
              v-model="discountType"
              :items="['จำนวนเงิน', 'เปอร์เซ็น']"
              placeholder="เลือกประเภทบัตรเงินสด"
              dense
              solo
              :rules="rules.required"
            >
            </v-select>
          </v-col>
        </v-row> -->
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">มูลค่าบัตรเงินสด</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              v-model="voucherPrice"
              solo
              dense
              :rules="rules.required"
              type="number"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">จำนวนบัตรเงินสด</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              v-model="voucherAmt"
              solo
              dense
              :rules="rules.required"
              type="number"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">ระยะเวลาของบัตรเงินสด</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-row align="center" justify="center">
              <v-col cols="6">
                <v-menu
                  ref="menufrom"
                  v-model="menufrom"
                  :close-on-content-click="false"
                  :return-value.sync="datefrom"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="datefrom"
                      label="วันที่เริ่ม"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      solo
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="datefrom"
                    no-title
                    scrollable
                    :min="firstDate"
                  >
                    <v-btn text color="primary" @click="menufrom = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menufrom.save(datefrom)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu
                  ref="menuto"
                  v-model="menuto"
                  :close-on-content-click="false"
                  :return-value.sync="dateto"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateto"
                      label="วันที่สิ้นสุด"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      solo
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="dateto" no-title scrollable>
                    <v-btn text color="primary" @click="menuto = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuto.save(dateto)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <!-- <v-col cols="12" md="12" sm="12" xs="12">
                <v-row align="center" justify="center">
                  <v-btn width="80px" class="mt-2 mb-4 mr-4" @click="clear()"
                    >เคลียร์</v-btn
                  >
                  <v-btn
                    width="80px"
                    class="mt-2 mb-4"
                    @click="getForm()"
                    color="#833133"
                    dark
                    >ค้นหา</v-btn
                  >
                </v-row>
              </v-col> -->
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">ลดเฉพาะบริษัทหรือไม่ ?</span>
            <!-- <span style="color: red">*</span> -->
          </v-col>
          <v-col cols="12" md="7" sm="7">
            <v-checkbox v-model="eppFlag" label="ลดเฉพาะบริษัท"></v-checkbox>
          </v-col>
        </v-row>
        <v-row v-if="eppFlag">
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">เลือกบริษัท</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-autocomplete
              v-model="company"
              :items="itemsCompany"
              item-text="companyName"
              item-value="id"
              placeholder="เลือกบริษัท"
              solo
              dense
              :rules="rules.required"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row align="center" justify="end">
          <v-btn @click="cancel()" class="mr-4">กลับ</v-btn>
          <v-btn @click="submit()" color="primary">บันทึก</v-btn>
        </v-row>
      </v-form>
    </v-card>
  </v-row>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      // discountType: "",
      voucherName: "",
      voucherPrice: null,
      voucherAmt: null,
      activeFlag: true,
      eppFlag: false,
      company: "",
      itemsCompany: [],
      // pic1: "",
      // picture_1: "",
      // showImage: "",
      // imageName1: "",
      datefrom: new Date().toISOString().substr(0, 10),
      dateto: new Date().toISOString().substr(0, 10),
      menufrom: false,
      menuto: false,
      firstDate: new Date("2021-06-01").toISOString().slice(0, 10),
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      lazy: false,
    };
  },
  created() {
    this.getDropdown();
  },
  methods: {
    async getDropdown() {
      // const cateDropdown = await this.axios.get(
      //   `${process.env.VUE_APP_API}/categories`
      // );
      // const typeDropdown = await this.axios.get(
      //   `${process.env.VUE_APP_API}/types`
      // );
      // const brandDropdown = await this.axios.get(
      //   `${process.env.VUE_APP_API}/brands`
      // );
      const companyDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/companies`
      );
      // const productDropdown = await this.axios.get(
      //   `${process.env.VUE_APP_API}/products`
      // );
      // this.itemsCategory = cateDropdown.data.data;
      // this.itemsType = typeDropdown.data.data;
      // this.itemsBrand = brandDropdown.data.data;
      this.itemsCompany = companyDropdown.data.data;
      // this.itemsProduct = productDropdown.data.data;
      console.log("itemsCompany", this.itemsCompany);
      //////////get dropdown list/////////////
    },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.pic1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    cancel() {
      this.$router.push("ManageVoucher");
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        var user = JSON.parse(
          Decode.decode(localStorage.getItem("userHealthAdmin"))
        );
        const auth = {
          headers: { Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImVtYWlsIjoiaXR0aXd1dC53b25nM0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYiQxMCRqU0UvY3RsSGhmTmJYemV5QXAvUHhPc3NOSS9HNG5NTHNsNlNBanFySzlVYzFMQmhDazh1UyIsImZpcnN0TmFtZSI6InNkZiIsImxhc3ROYW1lIjoic2RmIiwidGVsQ29kZSI6Iis2NiIsInRlbE51bWJlciI6IjIzNDU0NSIsImxpbmUiOiJkZnMiLCJnZW5kZXIiOiIiLCJhZ2UiOjEyLCJsaWNlbnNlTm8iOiIiLCJzY2hvb2wiOiIiLCJleHBlcmllbmNlIjoiZnNkZiIsImFsbGVyZ3kiOiJzZGYiLCJtaW5GZWUiOjEwLCJtYXhGZWUiOjIwLCJwcm92aW5jZSI6IuC4quC4oeC4uOC4l-C4o-C4m-C4o-C4suC4geC4suC4oyIsInVzZXJSZXF1ZXN0Ijoic2RmIiwiaWRDYXJkSW1nIjpudWxsLCJlZHVjYXRpb25JbWciOm51bGwsIndvcmtpbmdBcmVhIjoiamtqIiwibGF0IjpudWxsLCJsbmciOm51bGwsInNjaGVkdWxlIjoiWzIsM10iLCJyb2xlIjoiVVNFUiIsImlzUHJvIjpmYWxzZSwiaXNQdWJsaWMiOmZhbHNlLCJhY3RpdmVGbGFnIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIxLTExLTExVDA1OjQ3OjM4LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTExVDExOjUxOjM2LjAwMFoiLCJqb2JJZCI6NCwiZWR1Y2F0aW9uSWQiOjEsImxpY2Vuc2VJZCI6MSwic2VydmljZVR5cGVJZCI6MSwiZmVlQ3VycmVuY3lJZCI6MSwiZmVlRnJlcXVlbmN5SWQiOjIsImlhdCI6MTYzNjkwODA0Mn0.4ctskWtU_WO5pDZMDa6M8vxLFCJZPaRCNxhNMiwl0-E` },
        };
        const data = {
          // discountType: this.discountType,
          voucherName: this.voucherName,
          voucherPrice: this.voucherPrice,
          voucherAmt: this.voucherAmt,
          eppFlag: this.eppFlag,
          companyId: this.company || null,
          voucherStart: this.datefrom,
          voucherEnd: this.dateto,
          activeFlag: this.activeFlag,
        };
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/vouchers`,
          data,
          auth
        );
        console.log("createCate", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `สร้างบัตรเงินสดสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageVoucher");
        } else {
          this.$swal.fire({
            icon: "error",
            text: `สร้างบัตรเงินสดไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>